<template>

  <CardBox title="Total Posts" :count_number="tweetAllCount" icon_svg='<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="64" rx="8" fill="#141416"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.6667 24C22.6667 21.7909 24.4575 20 26.6667 20H40C42.2091 20 44 21.7909 44 24V40C44 42.2091 42.2091 44 40 44H26.6667C24.4575 44 22.6667 42.2091 22.6667 40V24Z" fill="#6127FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.6667 24.0001C22.6667 23.5326 22.7469 23.0838 22.8943 22.6667H21.3333C20.597 22.6667 20 23.2637 20 24.0001C20 24.7365 20.597 25.3334 21.3333 25.3334H22.6667V24.0001ZM22.6667 40.0001V38.6667H21.3333C20.597 38.6667 20 39.2637 20 40.0001C20 40.7365 20.597 41.3334 21.3333 41.3334H22.8943C22.7469 40.9164 22.6667 40.4676 22.6667 40.0001ZM21.3333 36.0001H22.6667V33.3334H21.3333C20.597 33.3334 20 33.9304 20 34.6667C20 35.4031 20.597 36.0001 21.3333 36.0001ZM22.6667 30.6667V28.0001H21.3333C20.597 28.0001 20 28.597 20 29.3334C20 30.0698 20.597 30.6667 21.3333 30.6667H22.6667ZM29.3333 25.3334C28.5971 25.3334 28 25.9316 28 26.6692C28 27.4068 28.5971 28.0049 29.3333 28.0049H37.3333C38.0693 28.0049 38.6667 27.4068 38.6667 26.6692C38.6667 25.9316 38.0693 25.3334 37.3333 25.3334H29.3333ZM28 32.0025C28 31.2649 28.5971 30.6667 29.3333 30.6667H37.3333C38.0693 30.6667 38.6667 31.2649 38.6667 32.0025C38.6667 32.7401 38.0693 33.3383 37.3333 33.3383H29.3333C28.5971 33.3383 28 32.7401 28 32.0025ZM28.0127 37.3552C28.0127 36.6176 28.6098 36.0195 29.346 36.0195H34.6794C35.4154 36.0195 36.0127 36.6176 36.0127 37.3552C36.0127 38.0928 35.4154 38.691 34.6794 38.691H29.346C28.6098 38.691 28.0127 38.0928 28.0127 37.3552Z" fill="#00D991"/>
</svg>
' />
  
</template> 

<script>
import CardBox from "../CardBox.vue"
import { fetchWrapper } from '../../helper/fetch-wrapper'
import { mapState } from 'vuex'
export default {
  components: {
    CardBox
  },
  data () {
    return {
      tweetAllCount: 0,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.getTweetAll()
  },
  methods: {
   async getTweetAll() {
      const tweet = await fetchWrapper.get(`/api/dashboard/tweet-all/${localStorage.getItem("project_user_id") || ""}`);
      this.tweetAllCount = tweet.data.toLocaleString();
    },
  }
}
</script>
