import { createRouter, createWebHistory } from "vue-router";
import DashboardKol from "@/components/DashboardKol.vue";
import HomePage from "@/views/HomePage.vue";
import KolMember from "@/components/KolMember/ListView.vue";
import KOLMemberCreate from "@/components/KolMember/KOLMemberCreate.vue";
import KOLMemberUpdate from "@/components/KolMember/KOLMemberUpdate.vue";
import KOLMemberProfile from "@/components/KolMember/KOlMemberProfile.vue";
import PaymentTransaction from "@/components/KolMember/PaymentTransaction.vue"
import AccessDenied from "@/components/AccessDenied.vue"
import axios from "axios";
import store from "@/store";

const protectGuards = (to, from, next) => {
  // have to check local storage
  var user_profile = localStorage.getItem("user")

  const UPO_API = process.env.VUE_APP_UPO_API_ENDPOINT || 'http://localhost:3000'
  // const UPO_WEBSITE = process.env.VUE_APP_UPO_WEBSITE || 'https://uponly.com'

  if (user_profile) {
    next()
  } else {
    // check token send URL
    const { token, address } = to.query
    
    
    if (token != undefined && address != undefined) {

      axios.get(`${UPO_API}/api/profile/detail/${address}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((response) => {
          var { data } = response.data
          if (data.name != undefined) {
            store.dispatch("login", data)
          }
          next()
        })
        .catch((error) => {
          console.log(error);
          next({ path: "/access_denied" })
        })
    } else {
      next({ path: "/access_denied" })
    }
  }
}

const checkProject = (to, from, next) => {
  var project_user_id = localStorage.getItem("project_user_id")
  const UPO_API = process.env.VUE_APP_API_ENDPOINT || 'http://localhost:3000'


  if (project_user_id) {
    next()
  } else {

    if (to.query.address == undefined && localStorage.getItem("wallet_address_user") == null) {
      next({ path: "/access_denied" })
      return
    } else {
      axios.get(`${UPO_API}/api/project/init-default-project/${localStorage.getItem("wallet_address_user")}`)
        .then((response) => {
          console.log(response);
          if (response.data.data._id != undefined) {
            localStorage.setItem("project_user_id", response.data.data._id)
            next()
          } else if (Array.isArray(response.data.data)) {
            localStorage.setItem("project_user_id", response.data.data[0]._id)
            next()
          } else {
            next({ path: "/access_denied" })
          }
        })
    }
  }
}
const removeQueryParams = (to) => {
  if (Object.keys(to.query).length)
    return { path: to.path, query: {}, hash: to.hash }
}

const routes = [
  { path: "/", redirect: { name: "DashboardHome" } },
  { path: "/login", redirect: "/" },
  { path: "/access_denied", component: AccessDenied },
  {
    path: '/home',
    beforeEnter: [protectGuards, checkProject, removeQueryParams],
    component: HomePage,
    children: [
      { path: '', redirect: { name: 'DashboardHome' } },
      { path: 'dashboard', name: 'DashboardHome', component: DashboardKol },
      { path: 'kol-member', name: 'KolMember', component: KolMember },
      {
        path: "kol-member/create",
        name: "KOLMemberCreate",
        component: KOLMemberCreate,
      },
      {
        path: "kol-member/profile/:member_id",
        name: "KOLMemberProfile",
        component: KOLMemberProfile,
      },
      {
        path: "kol-member/update/:id",
        name: "KOLMemberUpdate",
        component: KOLMemberUpdate,
      },
      { path: 'kol-member/payment-transaction/:kol_member_id', name: 'PaymentTransaction', component: PaymentTransaction, }
    ]
  }
]




const router = createRouter({
  history: createWebHistory("/"),
  routes
})

router.beforeEach(async (to, from, next) => {
  next()
  // const publicPages = ['/login']
  // const authRequired = !publicPages.includes(to.path)
  // const isLoggedIn = store.getters['isLogIn']

  // if (authRequired && !isLoggedIn) {
  //   next('/login')
  // } else if (to.path == '/login' && isLoggedIn == true) {
  //   next('/')
  // } else {
  //   next()
  // }
})

export default router;
