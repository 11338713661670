<template>
  <div class=" w-1/2 md:w-1/3 lg:w-64  md:top-0 md:left-0 lg:block bg-[#141416]  "
    :class="sideBarOpen ? '' : 'hidden'" id="main-nav">

    <div class="sticky top-0 z-40  px-[25px] w-full h-20 bg-[#141416] flex items-center border-1 border-r border-[#23262F]">
      <img :src="UPOLogo" />
    </div>

    <div class="flex items-center bg-[#6127FF] p-[25px]">
      <Vue3Lottie  :animationData="raderAnimation" :height="40" :width="40" />
      <p class="text-[21px] ml-3">Trackmate</p>
    </div>

    <div class="border-1 border-b border-[#23262F]" v-for="section in menu" :key="section.section_name">
      <p class="px-[25px] py-[5px] text-sm bg-[#23262F] font-semibold">{{ section.section_name }}</p>
      <div v-for="menu_item in section.menu" :key="menu_item.name"
        class="
        w-full 
        flex 
        items-center
        text-blue-400 
        hover:border-l-[#6127FF]
        hover:border-l-[5px]
        rounded-lg 
        cursor-pointer
        px-[25px]
        py-4
        "
        @click="link_page(menu_item.path)">
        <div v-html="menu_item.icon" class="pr-2"></div>
        <span class="text-[#777E90]">{{ menu_item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import UPOLogo from "@/assets/UPO-LOGO.png"
</script>
<script>
import { mapState } from 'vuex'
import { Vue3Lottie } from 'vue3-lottie'
import raderAnimation from "@/assets/radar.json"
export default {
  name: 'SidebarApp',
  computed: {
    Vue3Lottie,
    ...mapState(['sideBarOpen', 'menu'])
  },
  methods: {
    link_page(path){
      this.$router.push(path)
    }
  },
  setup(){
    return {
      raderAnimation
    }
  }
}
</script>
<style scoped>
.lottie-animation-container{
  margin: 0;
}
</style>