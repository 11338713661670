import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';


import store from './store'
createApp(App)

    .use(router)
    .use(store)
    // .use(Toasted)
    .use(ToastPlugin)
    .mount('#app')
