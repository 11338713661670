<template>
    <div class="container w-[350px]">
        <div class="max-w-md mx-auto mt-1 bg-[#191A1F] text-[#B1B5C3] p-5 rounded-md shadow-md">

            <div>
                <label for="keyword" class="block text-[#B1B5C3] font-bold mb-2">Keywords</label>
                <input @keypress.enter="handleSaveKeyword" v-model="keyword" type="text" id="keyword" name="username"
                    placeholder="Specify your keyword here"
                    class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">

                <label for="keyword" class="block text-[14px] text-[#747885] mt-2">Your words will be used in searches
                    from x.com.</label>
            </div>
            <div class="mb-3">
                <div class="inline-block bg-[#FFD166] text-black mr-3 mt-2 px-4 py-1 rounded-md font-medium"
                    v-for="(keyword, index) in key_words || []" :key="keyword">
                    {{ keyword }}
                    <icon-close :color="'#FFD166'" className="inline-block cursor-pointer bg-black rounded-full"
                        size="18" @click="removeKeyword(index)" />

                </div>
            </div>
            <div class="pt-3 border-t-[0.5px] border-b-[0.5px] border-[#3e414c]">

                <button type="submit" @click="handleSubmitKeyword"
                    class="mt-2 bg-[#6127FF] w-full rounded-[100px] hover:bg-blue-700 text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline">Save</button>
                <button type="submit"
                    class="mt-5 border w-full rounded-[100px]  text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline" @click="closeModal">Back</button>
            </div>
        </div>
    </div>
</template>

<script>
import IconClose from '../Icons/IconClose.vue'
import { mapState } from 'vuex'
import { useToast } from 'vue-toast-notification';
import { useStore } from 'vuex';

export default {
    props: {
        closeModal: {
            type: Function,
            required: true
        }
    },
    setup() {
        const store = useStore();
        const toast = useToast();
        return {
            store,
            toast
        }
    },
    components: { IconClose },
    computed: {
        ...mapState(['project']),
    },
    data() {
        return {
            keyword: "",
            key_words: [],
        }
    },
    created() {
        this.key_words = [].concat(this.project?.key_words || [])
    },
    methods: {
        handleSaveKeyword() {
            this.key_words.push(this.keyword)
            this.keyword = ""
        },
        handleSubmitKeyword() {

            if(this.keyword.length > 0){
                this.key_words.push(this.keyword)
                this.keyword = ""
            }
            this.$store.commit('setKeywordSetting', this.key_words)
            this.key_words = [].concat(this.project?.key_words || [])
            this.store.dispatch('triggerToast', { toast: this.toast, type: "success", message: "Save keyword successfully" })
        },
        removeKeyword(index_keyword) {
            this.key_words = this.key_words.filter((x, index) => index != index_keyword)
        }
    }
}
</script>

<style></style>