<template>
  <div class="flex justify-center h-screen w-screen items-center bg-[#141416] ">
    <div >
      <div class="flex">
        <div class="p-5 border-r">
          Access Denied
        </div>
        <div class="p-5">You are not authorized to access this page.</div>
      </div>
      <div class="mt-5 flex justify-center">
        <button class="bg-[#6127FF] p-5 rounded-xl" @click="openUPOWebsite" >Open UPO website</button>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false
    };
  },
  mounted() {

  },
  methods: {
    openUPOWebsite(){
       const UPO_WEBSITE = process.env.VUE_APP_UPO_WEBSITE || 'https://uponly.com'
       window.location = UPO_WEBSITE
    }
  }
};
</script>