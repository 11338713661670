
import { fetchWrapper } from '@/helper/fetch-wrapper'
import Vuex from 'vuex'

var menu = [
    {
        section_name: "MAIN",
        menu: [
            {
                name: "Dashboard",
                icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 16.8125C3 19.1067 4.778 21 7 21H15V9H3L3 16.8125Z" fill="#6127FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 7H21C21 4.7058 19.222 3 17 3H7C4.778 3 3 4.7058 3 7ZM17 21C19.222 21 21 19.1067 21 16.8125V9H17V21ZM12 17C12 17.5523 11.5523 18 11 18H7C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16H11C11.5523 16 12 16.4477 12 17ZM12 13C12 13.5523 11.5523 14 11 14H7C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12H11C11.5523 12 12 12.4477 12 13Z" fill="#00D991"/>
</svg>
`,
                path: "/home/dashboard",
                active: false
            }
        ],

    },
    {
        section_name: "TRACKMATE",
        menu: [
            {
                name: "Track List",
                icon: ` 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 3C13.0147 3 11 5.015 11 7.5C11 9.985 13.0147 12 15.5 12C17.9853 12 20 9.985 20 7.5C20 5.015 17.9853 3 15.5 3ZM15.5 5C16.8807 5 18 6.119 18 7.5C18 8.881 16.8807 10 15.5 10C14.1193 10 13 8.881 13 7.5C13 6.119 14.1193 5 15.5 5ZM12.0625 13.344C10.2284 14.101 9 15.956 9 18V20C9 20.552 9.4477 21 10 21H21C21.5523 21 22 20.552 22 20V18C22 16.004 20.7648 14.194 18.9375 13.406C18.6803 13.295 18.3806 13.292 18.125 13.406C17.2402 13.803 16.3782 14 15.5 14C14.6248 14 13.7598 13.792 12.8438 13.375C12.5919 13.26 12.3183 13.238 12.0625 13.344ZM12.447 15.39C13.3918 15.748 14.5309 16 15.5 16C16.4615 16 17.5535 15.765 18.4792 15.423C19.3818 15.937 20 16.899 20 18V19H11V18C11 16.835 11.6559 15.853 12.447 15.39Z" fill="#777E90"/>
<path d="M8 4C5.7909 4 4 5.791 4 8C4 10.209 5.7909 12 8 12C8.8294 12 9.615 11.747 10.2812 11.281C10.7339 10.965 10.8476 10.328 10.5312 9.875C10.2148 9.422 9.6089 9.34001 9.1562 9.65601C8.8233 9.88901 8.4162 10 8 10C6.8954 10 6 9.105 6 8C6 6.895 6.8954 6 8 6C8.3262 6 8.6246 6.07199 8.9062 6.21899C9.3962 6.47399 10.0264 6.30201 10.2812 5.81201C10.5361 5.32301 10.3337 4.69199 9.8438 4.43799C9.2787 4.14399 8.6494 4 8 4ZM5.0625 13.344C3.2284 14.101 2 15.956 2 18V20C2 20.552 2.4477 21 3 21H7C7.5523 21 8 20.552 8 20C8 19.448 7.5523 19 7 19H4V18C4 16.876 4.6667 15.844 5.433 15.39C6.1537 15.694 6.9161 15.924 7.5941 15.981C8.1445 16.028 8.6408 15.613 8.6875 15.062C8.7342 14.512 8.3365 14.046 7.7862 13.999C7.3472 13.962 6.8674 13.83 6.3125 13.594C6.1785 13.537 6.1007 13.492 5.8438 13.375C5.5919 13.26 5.3183 13.238 5.0625 13.344Z" fill="#777E90"/>
</svg>

`,
                path: "/home/kol-member",
                active: false
            }
        ]
    }
]

export default new Vuex.Store({
    state: {
        loading: false,
        sideBarOpen: false,
        menu: menu,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        keyword_setting: localStorage.getItem('keyword_setting') ? JSON.parse(localStorage.getItem('keyword_setting')) : [],
        project: {
            key_words:[]
        }
    },
    getters: {
        loading: state => state.loading,
        sideBarOpen: state => {
            return state.sideBarOpen
        },
        isLogIn: state => !!state.user,
        user: state => state.user,
        keywordSetting: state => state.keyword_setting
    },
    mutations: {
        setLoading(state, loading_status) {
            state.loading = loading_status
        },
        toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
        setUser(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('wallet_address_user', user.address)
        },
        clearUser(state) {
            state.user = null
            localStorage.removeItem('user')
            localStorage.removeItem('project_user_id')
            localStorage.removeItem('wallet_address_user')

        },
        setKeywordSetting(state, key_words) {
            state.project.key_words = key_words
            fetchWrapper.put(`/api/project/${state.project._id}`, state.project).then((res) => {
                state.project = res.data
            })
        },
        setCurrentProject(state, project) {
            state.project = project
        }
    },
    actions: {
        triggerToast({ commit }, { toast, message, type }) {
            // Display the toast notification
            toast.open({
                message:message,
                type: type
            });
            commit("setToast", toast)
        },
        setLoading(context, status) {
            context.commit('setLoading', status)
        },
        toggleSidebar(context) {
            context.commit('toggleSidebar')
        },
        login({ commit }, user) {
            commit('setUser', user)
        },
        logout({ commit }) {
            commit('clearUser')
        },
        setCurrentProject({ commit }, project) {
            commit('setCurrentProject', project)
        }
    }
})
