<template>
    <svg
      id="i-twitter"
      :width="width || 26"
      :height="height || 23"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      :class="className"
    >
      <path
        stroke-width="0"
        :fill="color || '#777e90'"
        d="M60 16 L54 17 L58 12 L51 14 C42 4 28 15 32 24 C16 24 8 12 8 12 C8 12 2 21 12 28 L6 26 C6 32 10 36 17 38 L10 38 C14 46 21 46 21 46 C21 46 15 51 4 51 C37 67 57 37 54 21 Z"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "IconTwitter",
    props: {
      color: {
        type: String,
        default: "#777e90"
      },
      className: {
        type: String,
        default: ""
      },
      size: {
        type: Number,
        default: 26
      },
      height: {
        type: Number,
        default: 23
      },
      width: {
        type: Number,
        default: 26
      }
    }
  };
  </script>
  