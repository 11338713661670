<template>
    <svg
      :class="className"
      :width="size"
      :height="size"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 31.5C6 30.6716 6.67157 30 7.5 30H28.5C29.3284 30 30 30.6716 30 31.5C30 32.3284 29.3284 33 28.5 33H7.5C6.67157 33 6 32.3284 6 31.5Z"
        fill="#6127FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 7.5V30H27V7.5C27 5.01472 24.9853 3 22.5 3H13.5C11.0147 3 9 5.01472 9 7.5ZM21 19.5C21.8284 19.5 22.5 18.8284 22.5 18C22.5 17.1716 21.8284 16.5 21 16.5C20.1716 16.5 19.5 17.1716 19.5 18C19.5 18.8284 20.1716 19.5 21 19.5Z"
        fill="#00D991"
      />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      className: String,
      size: {
        type: Number,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any specific styles for the icon here */
  </style>