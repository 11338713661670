<template>

  <CardBox title="View Tweet" :count_number="viewCount" icon_svg='<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="64" rx="8" fill="#141416"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.987 26.4556C24.7212 23.269 28.0496 21.3303 32 21.3303C35.9505 21.3303 39.2788 23.269 42.013 26.4556C42.9489 27.545 43.7673 28.709 44.4322 29.8716C44.8354 30.5756 45.081 31.1196 45.2252 31.4556C45.3694 31.7903 45.3694 32.2036 45.2252 32.5383C45.081 32.8743 44.8354 33.4183 44.4322 34.1223C43.7673 35.285 42.9489 36.449 42.013 37.5383C39.2788 40.725 35.9505 42.6636 32 42.6636C28.0496 42.6636 24.7212 40.725 21.987 37.5383C21.0511 36.449 20.2327 35.285 19.5679 34.1223C19.1647 33.4183 18.919 32.8743 18.7748 32.5383C18.6306 32.2036 18.6306 31.7903 18.7748 31.4556C18.919 31.1196 19.1647 30.5756 19.5679 29.8716C20.2327 28.709 21.0511 27.545 21.987 26.4556ZM31.9985 38.6666C35.6852 38.6666 38.6738 35.6818 38.6738 31.9999C38.6738 28.318 35.6852 25.3333 31.9985 25.3333C28.3118 25.3333 25.3231 28.318 25.3231 31.9999C25.3231 35.6818 28.3118 38.6666 31.9985 38.6666Z" fill="#00D991"/>
<circle cx="32" cy="32" r="4" fill="#6127FF"/>
</svg>
' />


</template>

<script>
import CardBox from "../CardBox.vue"
import { fetchWrapper } from '../../helper/fetch-wrapper'
import { mapState } from 'vuex'
export default {
  components: {
    CardBox
  },
  data() {
    return {
      viewCount: 0,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.getViewAll()
  },
  methods: {
    async getViewAll() {
      const view = await fetchWrapper.get(`/api/dashboard/view-all/${localStorage.getItem("project_user_id") || ""}`);
      this.viewCount = view.data.toLocaleString();
    }
  }
}
</script>
