<template>

 <CardBox title="Posts Today" :count_number="tweetTodayCount" icon_svg='<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="64" rx="8" fill="#141416"/>
<path d="M40 21.3303C42.9453 21.3303 45.3333 23.7202 45.3333 26.6678V42.6803C45.3333 43.7798 44.088 44.3829 43.208 43.7224L38.2507 40.0115H24C21.0547 40.0115 18.6667 37.6217 18.6667 34.674V26.6678C18.6667 23.7202 21.0547 21.3303 24 21.3303H40Z" fill="#6127FF"/>
<path d="M25.3333 26.6667C24.5969 26.6667 24 27.2641 24 28.0001C24 28.7361 24.5969 29.3334 25.3333 29.3334H38.6667C39.4031 29.3334 40 28.7361 40 28.0001C40 27.2641 39.4031 26.6667 38.6667 26.6667H25.3333ZM25.3333 32.0001C24.5969 32.0001 24 32.5974 24 33.3334C24 34.0694 24.5969 34.6668 25.3333 34.6668H34.6667C35.4031 34.6668 36 34.0694 36 33.3334C36 32.5974 35.4031 32.0001 34.6667 32.0001H25.3333Z" fill="#00D991"/>
</svg>
' />

  
</template> 

<script>
import CardBox from "../CardBox.vue"
import { fetchWrapper } from '../../helper/fetch-wrapper'
import { mapState } from 'vuex'
export default {
  components: {
    CardBox
  },
  data () {
    return {
      tweetTodayCount: 0,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.getTweetToday()
  },
  methods: {
   async getTweetToday() {
      const tweet = await fetchWrapper.get(`/api/dashboard/tweet-today/${localStorage.getItem("project_user_id") || ""}`);
      this.tweetTodayCount = tweet.data.toLocaleString();
    },
  }
}
</script>
