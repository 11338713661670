<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex">
      <SidebarApp />
      <div class="w-full bg-[#05070E] pl-0 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">
        <NavbarApp />
        <div class="p-6 bg-[#05070E] mb-20">
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <footer-app />
</template>

<script>
import { mapState } from 'vuex'

import SidebarApp from '../components/SidebarApp.vue'
import NavbarApp from '../components/NavbarApp.vue'
import FooterApp from '../components/FooterApp.vue'
import { fetchWrapper } from '../helper/fetch-wrapper'

import store from '@/store'
export default {
  name: 'HomePage',
  computed: {
    ...mapState(['sideBarOpen'])
  },
  components: {
    SidebarApp,
    NavbarApp,
    FooterApp
  }, created() {
    this.getProjectUserId(localStorage.getItem("project_user_id"))
  },
  methods: {
    async getProjectUserId(project_id) {
      const project = await fetchWrapper.get(`/api/project/${project_id}`);

      store.dispatch('setCurrentProject', project.data)
    }
  }
}
</script>
