<template>
    <svg
    :class="className"
    :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        :stroke="color">

        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

        <g id="SVGRepo_iconCarrier">
            <path d="M16 8L8 16M8.00001 8L16 16" :stroke="color" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>

    </svg>
</template>
<script>
export default {
  props: {
    color: String,
    className: String,
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>