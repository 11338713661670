<template>
  <div class="container w-[450px] mx-auto">
    <form @submit.prevent="submitForm" class="max-w-md mx-auto mt-8 bg-[#191A1F] p-8 rounded-md shadow-md">

      <div class="mb-4">
        <label for="wallet_address" class="block text-[#B1B5C3] font-bold mb-2">Wallet Address</label>
        <input v-model="form.wallet_address" type="text" id="wallet_address" name="wallet_address" required
          class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="token" class="block text-[#B1B5C3] font-bold mb-2">Token</label>
        <input v-model="form.token" type="text" id="token" name="token" required
          class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="amount" class="block text-[#B1B5C3] font-bold mb-2">Amount:</label>
        <input v-model.number="form.amount" type="number" id="amount" name="amount"
          class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="cost_usdt" class="block text-[#B1B5C3] font-bold mb-2">Cost (USDT):</label>
        <input v-model.number="form.cost_usdt" type="number" id="cost_usdt" name="cost_usdt"
          class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="description" class="block text-[#B1B5C3] font-bold mb-2">Description:</label>
        <textarea v-model="form.description" type="text" id="kol_member_id" name="kol_member_id" required
          class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500" />
      </div>
      <div class="mb-4">
        <label for="is_active" class="block text-[#B1B5C3] font-bold mb-2">Is Active:</label>
        <input v-model="form.is_active" type="checkbox" id="is_active" name="is_active"
          class="mr-2 leading-tight rounded-full p-1 text-[#00D991]">
        <span class="text-sm">Active</span>
      </div>
      <div class="flex  border-t-[0.5px] border-[#3e414c] pt-5">
        <button type="submit"
          class="bg-[#6127FF] w-full rounded-[100px] hover:bg-blue-700 text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline">CREATE
          PAYMENT</button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper";
import { mapState } from 'vuex'
export default {
  data() {
    return {
      form: {
        kol_member_id: this.$route.params.member_id,
        description: '',
        token: '',
        wallet_address: '',
        amount: 0,
        cost_usdt: 0,
        is_active: true,
        current_time: new Date()
      }
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async checkWalletAddress() {
      const payment = await fetchWrapper.post(`/api/payment-transaction/all/${this.form.kol_member_id}`);
      const member = await fetchWrapper.get(`/api/member/${this.form.kol_member_id}`)

      if (member.data) {
        this.form.cost_usdt = member.data.cost_usdt
      }

      if (payment.data.length > 0) {
        this.form.wallet_address = payment.data[0].wallet_address
      }


    },
    async submitForm() {
      // Handle form submission
      // You can perform API calls here to create the data
      // this.$store.dispatch('setLoading', true)

      await fetchWrapper.post("/api/payment-transaction/create", { ...this.form, project_id: localStorage.getItem("project_user_id") || "" || "" }).then(() => {
        this.$emit("created");
      }).catch((error) => {
        alert(`${error}`)

      })

    }
  },
  mounted() {
    // console.log(this.$route.path);
    this.checkWalletAddress()
  },

};
</script>