<template>
  <div id="home">

    <div class="container w-[450px]">
      <form @submit.prevent="submitForm" class="max-w-md mx-auto mt-1 bg-[#191A1F] p-10 rounded-md shadow-md">

        <div class="mb-4">
          <label for="username" class="block text-[#B1B5C3] font-bold mb-2">USERNAME:</label>
          <input v-model="form.username" type="text" id="username" name="username"
            class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F]  focus:outline-none focus:border-blue-500">
        </div>
        <!-- <div class="mb-4">
          <label for="twitter_user_id" class="block text-[#B1B5C3] font-bold mb-2">Twitter User ID (Optional):</label>
          <input v-model="form.twitter_user_id" type="text" id="twitter_user_id" name="twitter_user_id"
            class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <label for="followers_count" class="block text-[#B1B5C3] font-bold mb-2">Followers Count:</label>
          <input v-model.number="form.followers_count" type="number" id="followers_count" name="followers_count"
            class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
        </div> -->
        <div class="mb-4">
          <label for="cost_usdt" class="block text-[#B1B5C3] font-bold mb-2">FEE RECORD (USDT):</label>
          <input v-model.number="form.cost_usdt" type="number" id="cost_usdt" name="cost_usdt"
            class="w-full border rounded-md px-3 py-2 text-[#B1B5C3] bg-[#191A1F] focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <!-- <label for="is_active" class="block text-[#B1B5C3] font-bold mb-2">Is Active:</label> -->
          <input v-model="form.is_active" type="checkbox" id="is_active" name="is_active"
            class="mr-2 leading-tight rounded-full p-1 text-[#00D991]">
          <span class="text-sm">Active</span>
        </div>
        <div class="flex ">
          <button type="submit"
            class="bg-[#6127FF] w-full rounded-[100px] hover:bg-blue-700 text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline">Update</button>
        </div>
      </form>
    </div>

    <!-- <div class="flex flex-wrap p-3  border  rounded-md">


    </div> -->

  </div>
</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper";
import { mapState } from 'vuex'
export default {
  props: {
    member_id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      form: {
        twitter_user_id: '',
        followers_count: 0,
        username: '',
        cost_usdt: 0,
        is_active: true
      },
      id: null,
    };
  },
  methods: {
    async submitForm() {
      // Handle form submission

      // You can perform API calls here to create the data
      await fetchWrapper.put("/api/member/update", { ...this.form, project_id: localStorage.getItem("project_user_id") || "" }).then((data) => {
        // this.$store.dispatch('setLoading', false)
        this.$emit("updated");
        this.$toast.open({ type: "success", message: "Update KOL member successfully" })
        return data
      }).catch((error) => {
        // this.$store.dispatch('setLoading', false)

        alert(`${error}`)
        this.$toast.open({ type: "error", message: error.message })
        this.$emit("updated");
      })
      this.back()
    },
    back() {
      // Handle form submission

      this.$router.push({ name: 'KolMember' });
      // You can perform API calls here to create the data
    },
    async getById(id) {

      if (id == undefined) {
        id = this.$route.params.id
      }
      const member = fetchWrapper.get("/api/member/" + id).then((data) => {
        // console.log(data)
        return data.data
      }).catch((error) => {
        alert(`Error: ${error}`);
        // this.$emit("updated");
        this.back()
      })
      return member
    }
  },
  async created() {
    const member = await this.getById(this.member_id)
    this.form = member
  }
};
</script>