<template>
    <svg
      :width="size || '26px'"
      :height="size ? `${size * 0.5769}px` : '15px'"
      viewBox="0 0 26 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      :fill="color || '#777E90'"
      :class="className"
    >
      <title>Group 3</title>
      <g
        id="UPO-Prototype"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.900000036"
      >
        <g
          id="2.2-Crypto-game-analysis-detail"
          transform="translate(-1254.000000, -4692.000000)"
          :fill="color || '#777E90'"
        >
          <g id="Group-15" transform="translate(0.000000, 3513.000000)">
            <g id="Group-24" transform="translate(1075.000000, 1178.000000)">
              <g id="Group-3" transform="translate(179.606201, 1.565156)">
                <path
                  d="M25.3937992,7.21380298 C25.3937992,10.5729095 24.8300225,13.2976804 24.1343653,13.2976804 C23.4387081,13.2976804 22.8751747,10.5736397 22.8751747,7.21380298 C22.8751747,3.85396621 23.4389514,1.12992554 24.1343653,1.12992554 C24.8297792,1.12992554 25.3937992,3.85372279 25.3937992,7.21380298 M22.1802474,7.21380298 C22.1802474,10.9638316 20.5769997,14.0050401 18.5992796,14.0050401 C16.6215596,14.0050401 15.0183118,10.9638316 15.0183118,7.21380298 C15.0183118,3.46377434 16.6213162,0.422565865 18.5990363,0.422565865 C20.5767564,0.422565865 22.1800041,3.46280069 22.1800041,7.21380298 M14.3236279,7.21380298 C14.3236279,11.197752 11.1171324,14.4273625 7.16193562,14.4273625 C3.20673879,14.4273625 0,11.1970218 0,7.21380298 C0,3.23058419 3.20649547,0 7.16193562,0 C11.1173758,0 14.3236279,3.22985395 14.3236279,7.21380298"
                  id="Fill-1"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: "IconSubstack",
    props: {
      color: {
        type: String,
        default: "#777E90",
      },
      className: {
        type: String,
        default: "",
      },
      size: {
        type: String,
        default: "26px",
      },
    },
  };
  </script>
  