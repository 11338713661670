<template>
  
  <router-view />
  <LoadingSpinner v-if="loading" />
</template>
<script>
import { mapState } from 'vuex'
import LoadingSpinner from "./components/LoadingSpinner"
document.title = "Trackmate - Advanced KOL Performance Tracking & Management Solution"
export default {
  name: 'App',
  components:{LoadingSpinner},
  computed: {
    ...mapState(['loading']),
  }
}
</script>
<style>
@import url('./assets/css/tailwind.css');
</style>
