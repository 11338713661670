<template>
  <CardBox title="X Members" :count_number="twitterMemberCount" icon_svg='<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="64" rx="8" fill="#141416"/>
<path d="M36.6667 20C33.3529 20 30.6667 22.6867 30.6667 26C30.6667 29.3133 33.3529 32 36.6667 32C39.9804 32 42.6667 29.3133 42.6667 26C42.6667 22.6867 39.9804 20 36.6667 20ZM32.0833 33.792C29.6379 34.8013 28 37.2747 28 40V42.6667C28 43.4027 28.5969 44 29.3333 44H44C44.7364 44 45.3333 43.4027 45.3333 42.6667V40C45.3333 37.3387 43.6864 34.9253 41.25 33.8747C40.9071 33.7267 40.5075 33.7227 40.1667 33.8747C38.9869 34.404 37.8376 34.6667 36.6667 34.6667C35.4997 34.6667 34.3464 34.3893 33.1251 33.8333C32.7892 33.68 32.4244 33.6507 32.0833 33.792Z" fill="#00D991"/>
<path d="M26.6667 21.3333C23.7212 21.3333 21.3333 23.7213 21.3333 26.6666C21.3333 29.6119 23.7212 31.9999 26.6667 31.9999C27.7725 31.9999 28.782 31.696 29.6704 31.0746C27.392 27.688 27.7149 24.4453 28.9567 21.8333C28.2475 21.4973 27.4685 21.3333 26.6667 21.3333ZM22.75 33.7919C20.3045 34.8012 18.6667 37.2746 18.6667 39.9999V42.6666C18.6667 43.4026 19.2636 43.9999 20 43.9999L25.5371 44.0026C25.2251 42.9893 25.3168 41.3466 25.3373 40.0173C25.3807 37.1946 26.548 35.1812 27.0052 34.6506C26.1649 34.6439 25.1565 34.4399 24.4167 34.1252C24.238 34.0492 24.1343 33.9893 23.7917 33.8333C23.4559 33.6799 23.0911 33.6506 22.75 33.7919Z" fill="#6127FF"/>
</svg>

' />
  
</template> 

<script>
import CardBox from "../CardBox.vue"
import { fetchWrapper } from '../../helper/fetch-wrapper'
import { mapState } from 'vuex'
export default {
  components: {
    CardBox
  },
  data () {
    return {
      twitterMemberCount: 0,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    this.getMemberAll()
  },
  methods: {
    async getMemberAll() {
      const member = await fetchWrapper.get(`/api/dashboard/member-all/${localStorage.getItem("project_user_id")}`);
      this.twitterMemberCount = member.data.toLocaleString();
    },
  }
}
</script>
