<template>
  <div class="sticky top-0 z-40">
    <div class="w-full h-20 px-6 bg-[#141416]  flex items-center justify-between">
      <!-- left navbar -->
      <div class="flex">
        <nav aria-label="Main" data-orientation="horizontal" dir="ltr"
          class="relative z-10 flex max-w-max flex-1 items-center justify-center w-full">
          <div style="position:relative">
            <ul data-orientation="horizontal" class="group flex flex-1 list-none items-center justify-center space-x-1"
              dir="ltr">
              <li>
                <button @mouseover="solutions_section = true" id="solutions" data-state="closed" aria-expanded="false"
                  class="hover:bg-[#23262F] group inline-flex h-10 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 group bg-[#141416]">
                  Solutions <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-chevron-down relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                    aria-hidden="true">
                    <path d="m6 9 6 6 6-6"></path>
                  </svg></button>
              </li>
              <li>
                <a class="hover:bg-[#23262F] cursor-pointer group inline-flex h-10 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 bg-[#141416]"
                  @click="link_to_upo('/about')" data-radix-collection-item="">About</a>
              </li>
              <li>
                <a class="hover:bg-[#23262F] cursor-pointer group inline-flex h-10 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 bg-[#141416]"
                  @click="link_to_upo('/getting-started')" data-radix-collection-item="">Getting Started</a>
              </li>
            </ul>
          </div>
          <div v-if="solutions_section" class="bg-[#141416] rounded-md  absolute left-0 top-full flex justify-center"
            @mouseleave="solutions_section = false">
            <div class="grid w-[1000px] grid-cols-3 gap-x-8 gap-y-2 p-5 xl:w-[1400px] xl:grid-cols-4">
              <div class="section border-1 border-r border-[#23262F] pr-8 last:border-r-0"
                v-for="section in solutionMenus" :key="section.title">
                <h3 className="mb-4 text-[14px]">{{ section.title }}</h3>
                <div @click="link_to_upo(item.href)"
                  class="item mb-1 cursor-pointer hover:bg-[#23262F] px-3 py-2 rounded-md" v-for="item in section.items"
                  :key="item.title">
                  <img class="w-[32px] h-[32px] mr-3" :src="getImageUrl(item.icon)" alt="ICO">
                  <span>{{ item.title }}</span>
                  <span v-if="item.tag != undefined" class="new-badge">New</span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="flex">
        <!-- mobile hamburger -->
        <div class="md:hidden lg:hidden flex items-center mr-4">
          <button class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger"
            @click="toggleSidebar()">
            <svg class="h-5 w-5" v-bind:style="{ fill: 'black' }" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
      </div>
      <!-- right navbar -->
      <div class="flex items-center relative border-l-[0.5px] border-[#23262F]">
        <div class="flex items-center space-x-4 px-4 ">
          <button
            class="hover:bg-[#20293A] inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-color-gray disabled:border-color-gray disabled:text-white duration-300 hover:disabled:shadow-none bg-background hover:bg-accent hover:text-accent-foreground hover:border-color h-full rounded-full border-2 border-transparent px-3 py-[1px] transition hover:border-2"
            type="button" id="radix-:r3:" aria-haspopup="menu" aria-expanded="false" data-state="closed"
            @click="open_profile_menu = true">
            <div class="flex items-center gap-3">
              <div class="relative aspect-square w-[45px] overflow-hidden rounded-full">
                <img alt="user" class="rounded-full object-cover" :src="user.image"
                  style="height: 100%; width: 100%; inset: 0px; color: transparent;" />
              </div>
              <div class="flex flex-1 flex-col items-start">
                <div class="flex items-center"><img alt="icon-chain" loading="lazy" width="15" height="15"
                    decoding="async" data-nimg="1" class=" mr-2 rounded-full"
                    :src="require('@/assets/icons/polygon.webp')" style="color: transparent;">{{ user.name }}{{
                  open_profile_menu }}</div>
                <div class="flex items-center"><svg class="mr-[10px]" width="15" height="15" viewBox="0 0 20 16"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                      <g transform="translate(-1111.000000, -60.000000)" fill="#777E90" fill-rule="nonzero">
                        <g id="wallet" transform="translate(1111.000000, 60.000000)">
                          <path
                            d="M0,2 C0,0.9 0.9,0 2,0 L17,0 C17.5522847,0 18,0.44771525 18,1 L18,2 L2,2 L2,3 L19,3 C19.5522847,3 20,3.44771525 20,4 L20,14 C20,15.1045695 19.1045695,16 18,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,2 Z M16.5,11 C17.3284271,11 18,10.3284271 18,9.5 C18,8.67157288 17.3284271,8 16.5,8 C15.6715729,8 15,8.67157288 15,9.5 C15,10.3284271 15.6715729,11 16.5,11 Z"
                            id="Shape"></path>
                        </g>
                      </g>
                    </g>
                  </svg>{{ shortenAddress(user.address) }}</div>
              </div>
            </div>
          </button>
        </div>
        <div v-if="open_profile_menu == true"
          class="w-[240px] bg-[#141416] border-[0.5px] rounded-md border-[#23262F]  absolute left-0 top-full">
          <div @click="handleDisconnect"
            class="relative w-full z-50 border-1 border-[#23262F] h-[40px] flex  items-center rounded-sm text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 group cursor-pointer px-[16px] py-[8px]">
            <IconDisconnect size="24" className="group-hover:hidden" />
            <IconDisconnectColor size="24" className="hidden group-hover:block" />
            <span>Disconnect</span>
          </div>
          <div class="backdrop fixed inset-0 z-49 " @click="open_profile_menu = false"></div>
        </div>
      </div>
    </div>
    <!-- dropdown menu -->
    <div
      class="z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 w-[250px] border-[#23262F] bg-[#141416]"
      :class="dropDownOpen ? '' : 'hidden'">
      <a href="#" class="block px-4 py-2 hover:bg-gray-200" @click="handleDisconnect">Disconnect</a>
    </div>
    <!-- dropdown menu end -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IconDisconnect from './Icons/IconDisconnect.vue';
import IconDisconnectColor from './Icons/IconDisconnectColor.vue';
import { useAuthStore } from '@/store/auth'
const authStore = useAuthStore()
const solutionMenus = [
  {
    title: "Fundraising and Investments",
    items: [
      {
        title: "ICO",
        icon: "/assets/icons/ico.webp",
        href: "/launchpad"
      },
      {
        title: "Staking",
        icon: "/assets/icons/staking.webp",
        href: "/staking-service"
      },
      {
        title: "Lending",
        icon: "/assets/icons/lending.webp",
        href: "/lending",
        tag: "New"
      }
    ]
  },
  {
    title: "Marketplace and Trading",
    items: [
      {
        title: "Marketplace",
        icon: "/assets/icons/marketplace.webp",
        href: "/marketplace"
      },
      {
        title: "Gachapon",
        icon: "/assets/icons/gachapon.webp",
        href: "/gachapon"
      },
      {
        title: "Loot Box Open API",
        icon: "/assets/icons/lootbox-api.webp",
        href: "/lootbox"
      },
      {
        title: "Marketplace Open API",
        icon: "/assets/icons/marketplace-open-api.webp",
        href: "/marketplace#open-api-services"
      }
    ]
  },
  {
    title: "Marketing and Promotion",
    items: [
      {
        title: "Blog",
        icon: "/assets/icons/blog.webp",
        href: "/write2earn"
      },
      {
        title: "Banner (Ads)",
        icon: "/assets/icons/banner-ads.webp",
        href: "/ad-services"
      },
      {
        title: "TrackMate",
        icon: "/assets/icons/kol.webp",
        href: "/track-mate",
        tag: "New"
      }
    ]
  },
  {
    title: "Others",
    items: [
      {
        title: "Tournament",
        icon: "/assets/icons/tournament.webp",
        href: "/tournaments/status/all"
      },
      {
        title: "Betting Service",
        icon: "/assets/icons/betting-contest.webp",
        href: "/watch-and-earn"
      },
      {
        title: "Project",
        icon: "/assets/icons/project-evaluation.webp",
        href: "/list-with-us"
      }
    ]
  }
]; 

export default {
  components: {
    IconDisconnect,
    IconDisconnectColor
  },
  name: 'NavbarApp',
  computed: {
    ...mapState(['sideBarOpen', 'user'])
  },
  data() {
    return {
      solutions_section: false,
      dropDownOpen: false,
      solutionMenus: solutionMenus,
      open_profile_menu: false
    }
  },
  methods: {
    handleDisconnect() {
      authStore.logout()
      window.location.reload()
    },
    shortenAddress(address) {
      return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`
    },
    link_to_upo(path) {
      window.location = `https://www.uponly.com${path}`
    },
    getImageUrl(iconPath) {
      try {
        const relativePath = iconPath.replace('/assets/icons/', './');
        const icons = require.context('@/assets/icons', false, /\.webp$/);
        return icons(relativePath);
      } catch (error) {
        console.error('Error loading icon:', error);
        return null; // Or a default fallback icon
      }
    },
    toggleSidebar() {
      this.$store.dispatch('toggleSidebar')
    },
    toggleDropDown() {
      this.dropDownOpen = !this.dropDownOpen;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.new-badge {
  background-color: orange;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
}

.container-box {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.section {
  width: 300px;
  margin: 10px;
}

.section h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>