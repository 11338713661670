<template>
    <footer class="footer border-t border-b border-1 border-[#23262F] bg-[#141416]">
        <div
            class="mx-auto flex w-full max-w-screen-2xl items-start justify-start p-0 laptops:flex-row extraLaptops:w-[auto] extraLaptops:px-[9vw] extraLaptops:text-left tvs:px-[10vw]">
            <!-- Left Section -->
            <div class="footer-section flex ">
                <div class="footer-logo mt-[20px] mr-[5px]">
                    <img src="@/assets/logo-icon.png" alt="UPO Logo" />
                </div>
                <div class="footer-contract">
                    <p>UPO Contract : 0x9db...ac96</p>
                </div>
                <div class="footer-links">
                    <a class="link" href="https://www.uponly.com/getting-started">Getting Started</a>
                    <a class="link" href="https://www.uponly.com/about">About</a>
                </div>
            </div>

            <!-- Middle Section -->
            <div class="footer-section border-r border-l border-1 border-[#23262F]">
                <h4>UPO TOKENS</h4>
                <a class="link" href="https://www.kucoin.com/trade/UPO-USDT" target="_blank">Buy UPO Tokens</a>
                <a class="link" href="https://doc.uponly.com/UpOnly_2024.pdf" target="_blank">Whitepaper</a>
                <a class="link" href="https://doc.uponly.com/UPO_One_Pager.pdf" target="_blank">Onepager</a>
                <a class="link" href="https://doc.uponly.com/UPO_Tokenomics.pdf" target="_blank">Tokenomics</a>
            </div>

            <!-- Right Section -->
            <div class="footer-section newsletter-section">
                <h4>NEWSLETTER</h4>
                <p>Subscribe to our Newsletter to get the latest updates.</p>
                <form @submit.prevent="subscribeNewsletter">
                    <input class="bg-[#141416] text-[12px] w-full rounded-full" type="email"
                        placeholder="Enter your email" v-model="email" />
                </form>
            </div>
        </div>
    </footer>
    <div class="md:pl-20 bg-[#141416]">
        <div
            class="mx-auto flex max-w-screen-2xl items-center justify-between px-[5vw] py-[50px] laptops:flex-row laptops:px-[10vw]">
            <div aria-label="footer-copyright"
                class="mb-[20px] justify-center font-font-semiBold text-[12px] text-color-gray laptops:mb-0">
                Copyright 2024 © UpOnly
            </div>
            <div>
                <ul class="m-0 flex list-none justify-center p-0">
                    <li class="mx-[15px] flex items-center first:ml-0 last:mr-0">
                        <a class="text-color-gray transition-all duration-300 hover:text-color-white"
                            href="mailto:up@uponly.com" target="_blank" rel="noreferrer">
                            <icon-email />
                        </a>
                    </li>
                    <li class="mx-[15px] flex items-center first:ml-0 last:mr-0">
                        <a href="https://twitter.com/UpOnlyOfficial"
                            class="icon-twitter text-color-gray transition-all duration-300 hover:text-color-white"
                            target="_blank" rel="noreferrer">
                            <icon-twitter />
                        </a>
                    </li>
                    <li class="mx-[15px] flex items-center first:ml-0 last:mr-0">
                        <a href="https://t.me/UpOnlyOfficial"
                            class="icon-telegram text-color-gray transition-all duration-300 hover:text-color-white"
                            target="_blank" rel="noreferrer">
                            <icon-telegram />
                        </a>
                    </li>
                    <li class="mx-[15px] flex items-center first:ml-0 last:mr-0">
                        <a href="https://uponlyofficial.substack.com/"
                            class="icon-substack text-color-gray transition-all duration-300 hover:text-color-white"
                            target="_blank" rel="noreferrer">
                            <icon-substack />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import IconEmail from "@/components/Icons/IconEmail.vue"
import IconTwitter from "@/components/Icons/IconTwitter.vue"
import IconTelegram from "@/components/Icons/IconTelegram.vue"
import IconSubstack from "@/components/Icons/IconSubstack.vue"

export default {
    components: { IconEmail, IconTwitter, IconTelegram, IconSubstack },
    data() {
        return {
            email: ''
        };
    },
    methods: {
        subscribeNewsletter() {
            // Handle the newsletter subscription logic here
            console.log('Subscribed with:', this.email);
            this.email = ''; // Clear the email field after submission
        },
        shortenAddress(address) {
            `${address.slice(0, 5)}...${address.slice(address.length - 4)}`
        }

    }
};
</script>

<style scoped>
.link:hover {
    color: #ffffff;
}

.footer {

    padding: 40px 20px;
    display: flex;
    justify-content: center;
}

.footer-content {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.footer-section {
    flex: 1;
    padding: 80px;
}

.footer-logo img {
    max-width: 50px;
}

.footer-contract p {
    color: #b3b3b3;
    margin: 10px 0;
}

.footer-links a,
.footer-section a {
    display: block;
    color: #b3b3b3;
    text-decoration: none;
    margin-bottom: 10px;
}

.footer-section h4 {
    margin-bottom: 20px;
}

.newsletter-section p {
    margin-bottom: 20px;
}

.newsletter-section input[type="email"] {
    padding: 10px;
    border: none;
    margin-right: 10px;
    max-width: 250px;
    border: 1px solid gray;
}

.newsletter-section button {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #1a1a1a;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>