<template>
    <svg
      :width="width || 26"
      :height="height || 23"
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      :fill="color || '#777e90'"
      xmlSpace="preserve"
      :class="className"
    >
      <path
        d="M29.3,2.6c-0.3-0.2-0.7-0.3-1-0.2L3,11.7c-0.4,0.1-0.7,0.5-0.7,0.9c0,0.4,0.3,0.8,0.7,0.9l10.2,3.8l10-10
          c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-9.8,9.8l6.6,10.6c0.2,0.3,0.5,0.5,0.8,0.5c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.4,0.8-0.7l6.2-25.2
          C29.7,3.3,29.6,2.9,29.3,2.6z"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "IconTelegram",
    props: {
      color: {
        type: String,
        default: "#777e90"
      },
      className: {
        type: String,
        default: ""
      },
      size: {
        type: Number,
        default: 26
      },
      height: {
        type: Number,
        default: 23
      },
      width: {
        type: Number,
        default: 26
      }
    }
  };
  </script>
  